import { render, staticRenderFns } from "./Movies-ALL.vue?vue&type=template&id=7c44f47e"
import script from "./Movies-ALL.vue?vue&type=script&lang=js"
export * from "./Movies-ALL.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
  <div>
    <header class="main-header">
      <div class="row row-page-top movies-all">
        <img
          v-if="provider"
          class="logo-channel"
          :src="getLogo()"
          loading="lazy"
        />
        <i v-if="provider" class="zmdi zmdi-circle"></i>

        <h1 class="poster-rail--title section-title" v-if="selectedGenre">
          Películas en {{ selectedGenre.name }}
        </h1>

        <div class="filters-toolbar">
          <Select
            cssClass="dropdown-channel"
            :list="genres"
            :onSelect="onSelectGenre"
            :showFullLoader="showFullLoader"
            keyField="idCatalog"
            textField="name"
            title="Género"
            searchTitle="Filtrar por género:"
          />
        </div>
      </div>
    </header>

    <main class="wrapper container-fluid virtual-scroll" role="main">
      <section class="row poster-grid">
        <template v-if="selectedGenre && selectedGenre.movies">
          <CardVOD
            v-for="(item, index) of selectedGenre.movies"
            :key="item.idAsset"
            :data="item"
            class="space-between-card"
            :sliderId="'slider-peliculas'"
            :sliderTitle="'Películas en ' + selectedGenre.name"
            :cardId="'card-' + item.idAsset"
          />
        </template>
      </section>
    </main>

    <Loader v-if="showFullLoader" />
  </div>
</template>

<script>
import * as providers from "@/observables/filterProvider";

export default {
  name: "MoviesALL",

  components: {
    CardVOD: () => import("@/components/Card-VOD.vue"),
    FiltersScroll: () => import("@/components/Filters-scroll.vue"),
    Loader: () => import("@/components/Loader.vue"),
    LoaderScroll: () => import("@/components/Loader-scroll.vue"),
    Select: () => import("@/components/Select.vue"),
  },

  data() {
    return {
      isScroll: false,
      showFullLoader: true,
      genres: [],
      selectedGenre: null,
      provider: null,
    };
  },

  created() {
    let self = this;
    if (!self.$route.params.genreId) {
      return;
    }

    const providerId = self.$route.params.providerId;

    self.loading(true);

    //Traigo los favoritos para que se guarden en el buffer del core y así evito llamadas extra al traer los géneros
    telecentro.tplay.core.favoritos.traerFavoritos(function (favs) {
      telecentro.tplay.core.peliculas.todosLosGeneros(function (genres) {
        self.genres = genres;
        self.setGenreById(self.$route.params.genreId, function () {
          providers.update("MOVIES", self.genres);
          if (providerId) {
            self.provider = providers.getProviderById(providerId);
            self.selectedGenre.movies = self.selectedGenre.movies.filter(
              (q) => q.channel.id === parseInt(providerId)
            );
          }
          self.loading(false);
        });
      });
    });
  },

  methods: {
    loading(show) {
      document.documentElement.style.cursor = show ? "wait" : "default";
      this.showFullLoader = show;
    },

    setGenreById(idCatalog, onFinish) {
      idCatalog = parseInt(idCatalog);

      for (let i = 0, l = this.genres.length; i < l; i++) {
        let g = this.genres[i];
        if (g.idCatalog === idCatalog) {
          this.setGenre(g, onFinish);

          break;
        }
      }
    },

    setGenre(genre, onFinish) {
      let self = this;

      self.selectedGenre = genre;

      //Esto no es completamente necesario, porque el breadcrumb ya usa la ruta de la url.
      //Pero la url puede no tener el nombre correcto del género (ya que se mapea por id), así
      //que acá me aseguro de usar el nombre correcto en el breadcrumb.
      this.$bus.$emit("breadcrumb:update", {
        genreName: { text: genre.name },
      });

      if (self.selectedGenre.movies) {
        onFinish(genre);
      } else {
        telecentro.tplay.core.peliculas.peliculasPorGenero(
          genre.idCatalog,
          function (movies) {
            self.selectedGenre.movies = movies;
            onFinish(genre);
          }
        );
      }
    },

    onSelectGenre(genre) {
      let self = this;
      const providerId = self.$route.params.providerId;
      self.loading(true);
      if (providerId) {
        self.$router.push(
          "/peliculas/" + genre.idCatalog + "/" + genre.name + "/" + providerId
        );
      } else {
        self.$router.push("/peliculas/" + genre.idCatalog + "/" + genre.name);
      }
      self.setGenre(genre, function () {
        self.loading(false);
      });
    },

    getLogo() {
      const self = this;
      return self.provider ? self.provider.channel.logo : null;
    },
  },

  watch: {
    $route(to, from) {
      let self = this;
      const providerId = self.$route.params.providerId;
      self.loading(true);
      self.setGenreById(to.params.genreId, function () {
        providers.update("MOVIES", self.genres);
        if (providerId) {
          self.provider = providers.getProviderById(providerId);
          self.selectedGenre.movies = self.selectedGenre.movies.filter(
            (q) => q.channel.id === parseInt(providerId)
          );
        }
        self.loading(false);
      });
    },
  },
};
</script>